<template>
  <div
    class="d-flex w-100 bg-white p-1 form-control commitment-bar"
  >
    <div class="row p-3 w-100 flex-nowrap">
      <div class="d-flex flex-row flex-fill">
        <span class="commitment-bar__label text-uppercase mt-2">
          Cost Commitment:
        </span>&nbsp;
        <span class="commitment-bar__value mt-2 ms-4">
          <strong>{{ formattedCommitmentCost }}</strong> (incl. {{ formattedAdditionalCost }} add.)
        </span>
      </div>
      <div class="d-flex flex-row flex-fill">
        <span class="commitment-bar__label text-uppercase mt-2">
          Hours Commitment:
        </span>&nbsp;
        <span class="commitment-bar__value mt-2 ms-4">
          <strong>{{ commitmentHours }} Hrs.</strong> (incl. {{ additionalHours }} Hrs add.)
        </span>
      </div>
      <div class="justify-content-end d-flex pe-0 flex-fill w-25">
        <button
          v-if="isSubmitBtnVisible"
          :disabled="isSubmitPlanButtonDisabled"
          class="btn btn-primary commitment-bar__button-submit m-0 p-0 mt-1 me-1 small"
          @click="onPlanSubmit"
        >
          {{ submitButtonText }}
        </button>
        <Dropdown
          v-if="isActionsBtnVisible"
          :disabled="isActionsButtonDisabled"
          label="Action"
        >
          <DropdownItem
            @click="onPlanApprove"
          >
            Approve
          </DropdownItem>
          <DropdownItem
            @click="onPlanReject"
          >
            Reject
          </DropdownItem>
        </Dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import Dropdown from '@/components/common/Dropdown';
import { planStatuses } from '@/components/LaborPlanning/constants';
import DropdownItem from '@/components/common/DropdownItem';
import { toUSMoneyFormat } from '@/components/LaborPlanning/utils';
import { userPrivileges } from '@/components/constants';
import { hasPrivilege } from '@/service/userAccess';

export default {
  components: {
    DropdownItem,
    Dropdown
  },
  props: [
    'isEditAllowed',
    'isReviewAllowed',
    'modelValue',
    'commitmentCost',
    'commitmentHours',
    'errors',
    'planStatus',
    'planStartDate',
    'isDirty',
    'additionalHours',
    'additionalCost'
  ],
  emits: [ 'submitPlan', 'approvePlan', 'rejectPlan' ],
  data() {
    return {
      dropdown: null,
    };
  },
  computed: {
    isSubmitBtnVisible() {
      return hasPrivilege(userPrivileges.submitLpPlan);
    },
    isActionsBtnVisible() {
      return hasPrivilege(userPrivileges.updateLpPlanStatus);
    },
    submitButtonText() {
      if ([ planStatuses.REJECTED, planStatuses.RESUBMISSION_ALLOWED ].includes(this.planStatus)) {
        return 'Resubmit Plan';
      }

      if (this.planStatus === planStatuses.SUBMITTED) {
        return 'Submitted';
      }

      if (this.planStatus === planStatuses.APPROVED) {
        return 'Approved';
      }

      return 'Submit Plan';
    },
    isActionsButtonDisabled() {
      return !this.isReviewAllowed;
    },
    isSubmitPlanButtonDisabled() {
      return !this.isEditAllowed || !this.isDirty;
    },
    formattedCommitmentCost() {
      return toUSMoneyFormat(this.commitmentCost);
    },
    formattedAdditionalCost() {
      return toUSMoneyFormat(this.additionalCost);
    }
  },
  methods: {
    onPlanSubmit() {
      this.$emit('submitPlan');
    },
    onPlanApprove() {
      this.$emit('approvePlan');
    },
    onPlanReject() {
      this.$emit('rejectPlan');
    }
  }
};
</script>
<style>
.commitment-bar {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #FFF5E5!important;
  font-family: Roobert-Regular, serif;
}

.commitment-bar__text-input {
  margin: 0!important;
  width: 100px;
}

.commitment-bar__text-input input {
  line-height: 18px !important;
}

.commitment-bar__label {
  font-family: Roobert-Regular, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #EF6327;
}

.commitment-bar__value {
  font-family: Roobert-Regular, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.commitment-bar__value.bold__value {
  font-size: 17px;
  font-weight: 900;
}

.commitment-bar__button-submit {
  width: 200px;
  height: fit-content;
}
</style>
